import Swiper, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  EffectCoverflow,
  Thumbs,
  // Lazy,
} from 'swiper';

Swiper.use([
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
  // Lazy,
]);

$(document).on('ready', function () {
  // Project slider
  const homeSlider = new Swiper('.home-swiper', {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    grabCursor: false,
    cssMode: false,
    freeMode: false,
    // navigation: {
    //   nextEl: '.home-button-next',
    //   prevEl: '.home-button-prev',
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
  });
  homeSlider.init();

  if (window.innerWidth < 768 && $('.home-swiper').length) {
    $('.home-swiper').find('.swiper-wrapper').addClass('disabled');
  }

  if (window.innerWidth < 1024) {
    const projectSlider = new Swiper('.home-page__projects__cards', {
      spaceBetween: 16,
      loop: true,
      slidesPerView: 1.25,
      grabCursor: true,
      cssMode: false,
      freeMode: false,
      // navigation: {
      //   nextEl: '.home-button-next',
      //   prevEl: '.home-button-prev',
      // },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      //   renderBullet: function (index, className) {
      //     return '<span class="' + className + '"></span>';
      //   },
      // },
      breakpoints: {
        // when window width is >= 320px
        900: {
          slidesPerView: 2,
        },
      },
    });
    projectSlider.init();

    const homePostSlider = new Swiper('.home-page__posts__inner', {
      spaceBetween: 0,
      loop: true,
      slidesPerView: 1,
      grabCursor: true,
      cssMode: false,
      freeMode: false,
      // navigation: {
      //   nextEl: '.home-button-next',
      //   prevEl: '.home-button-prev',
      // },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      //   renderBullet: function (index, className) {
      //     return '<span class="' + className + '"></span>';
      //   },
      // },
      breakpoints: {
        // when window width is >= 320px
        900: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
      },
    });
    homePostSlider.init();
  }

  const relatedPosts = new Swiper('.single-post__related__inner', {
    spaceBetween: 40,
    loop: true,
    slidesPerView: 1,
    grabCursor: true,
    // cssMode: true,
    freeMode: false,
    breakpoints: {
      478: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  });
  relatedPosts.init();
});
