/* global ajax_object */
function projectsAjax(showPosts, term, paged) {
  const data = {
    action: 'projects_ajax_load',
    nonce: ajax_object.nonce,
    showPosts: showPosts,
    term: term,
    paged: paged,
  };

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      if ($('.projects-archive__quary').length) {
        $('.projects-archive__quary')
          .find('.quary-container')
          .html(response.html);
        if (response.amount < response.show_posts && !response.paged) {
          $('.pagination-wrapper').hide();
        } else {
          $('.pagination-wrapper').show();
        }
      }
      $('html, body').animate(
        {
          scrollTop: $('.projects-archive__quary').offset().top,
        },
        1000
      );
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
let category = '';
function postsAjax(showPosts, catId, paged, amount, catAmount) {
  const data = {
    action: 'post_ajax_load',
    nonce: ajax_object.nonce,
    show_posts: showPosts,
    cat_id: catId,
    cat_amount: catAmount,
    paged: paged,
    amount: amount,
  };

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'post',
    dataType: 'json',
    data,
    success: function (response) {
      if ($('.blog-page__posts__container').length) {
        $('.blog-page__posts__container').html(response.html);
        if (
          response.amount < response.show_posts &&
          !response.paged &&
          response.cat_amount < response.show_posts
        ) {
          $('.blog-page__posts__pagination').hide();
        } else {
          $('.blog-page__posts__pagination').show();
        }
      }
      $('html, body').animate(
        {
          scrollTop: $('.blog-page__posts__container').offset().top,
        },
        1000
      );
    },
    error: function (e) {
      console.log('error', e);
    },
  });
}
$(document).on('ready', function () {
  if ($('.projects-tax-term').length) {
    $('.projects-tax-term').on('click', function () {
      $('.projects-tax-term').removeClass('is-active');
      $(this).addClass('is-active');
      let term = $(this).data('term'),
        showPosts = $(this).data('show');
      projectsAjax(showPosts, term);
    });
  }
  if ($('.projects-pagination-items').length) {
    $('.projects-pagination-items').on('click', function () {
      $('.projects-pagination-items').removeClass('is-active');
      $(this).addClass('is-active');
      let paged = $(this).data('paged'),
        showPosts = $(this).data('show');
      projectsAjax(showPosts, '', paged);
    });
  }

  if ($('.quary-filter__mobile-tab').length) {
    if (window.innerWidth < 640) {
      $('.quary-filter__mobile-tab')
        .siblings('span')
        .wrapAll('<div class="project-terms-wrapper">');
      // $('.project-terms-wrapper').hide();
    }
    $('.quary-filter__mobile-tab').on('click', function () {
      $(this).toggleClass('is-active');
      $(this).siblings('.project-terms-wrapper').toggle('100');
    });
  }

  if ($('.blog-page__posts__categories').length) {
    let cat = $('.blog-page__posts__categories').find('.category-name');
    cat.on('click', function () {
      let catId = $(this).data('cat'),
        show = $(this).data('show'),
        amount = $(this).data('amount'),
        catAmount = $(this).data('cat-count');
      category = catId;
      postsAjax(show, catId, null, amount, catAmount);
      console.log(category);
    });
  }

  if ($('.blog-page__posts__pagination').length) {
    let pagItmes = $('.blog-page__posts__pagination').find('.pagination-item');
    pagItmes.on('click', function () {
      pagItmes.removeClass('is-active');
      $(this).addClass('is-active');
      let paged = $(this).data('paged'),
        amount = $(this).data('amount'),
        showPosts = $(this).data('show');
      postsAjax(showPosts, category, paged, amount, '');
    });
  }
});
